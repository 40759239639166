import { makeStyles } from '@material-ui/core/styles';
import { GRAY_PRIMARY, WHITE } from '@theme_color';
import {
    CenterAbsolute, CreateMargin, CreatePadding, CreateBorder,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        ...CreatePadding(10, 10, 10, 10),
        width: '100%',
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: WHITE,
        position: 'fixed !important',
        zIndex: '2 !important',
        ...CreateMargin(0, 0, 4, 0),
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
        [theme.breakpoints.up('md')]: {
            maxWidth: 500,
            ...CenterAbsolute,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: 500,
            ...CenterAbsolute,
        },
    },
    leftContainer: {
        position: 'absolute',
        left: 0,
    },
    rightContainer: {
        position: 'absolute',
        right: 0,
    },
    centerContainer: {
        alignItems: 'center',
        textAlign: 'center',
        flexGrow: 1,
        ...CreatePadding(0, '20%', 0, '20%'),
    },
    centerBrandContainer: {
        alignItems: 'center',
        textAlign: 'center',
        flexGrow: 1,
        ...CreatePadding(0, '10%', 0, '10%'),
    },
    backIcon: {
        fontSize: 30,
    },
    headerAbsolute: {
        position: 'absolute',
        zIndex: 1,
        borderBottom: 'none',
    },
    headerRelative: {
        position: 'relative',
    },
    title: {
        fontSize: 14,
    },
    hamburgerList: {
        width: 300,
    },
    imgLogoHamburger: {
        width: 100,
        height: '100%',
    },
    logo: {
        position: 'absolute',
        zIndex: 99,
        ...CenterAbsolute,
    },
    navRightMenu: {
        position: 'absolute',
        right: '1rem',
    },
}));

export default useStyles;
